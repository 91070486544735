import React, { useState } from "react"

import CountUp from "react-countup"
import Layout from "../components/layout"
import Seo from "../components/seo"
import blankStatePricing from "../images/pricing/blank-state-pricing.svg"
import { motion } from "framer-motion"

const pricing = {
  monthly: 18,
  yearly: 15,
}

function calculate(numberOfUsers, pricePerUser) {
  return parseInt(numberOfUsers || 1) * pricePerUser
}

const Pricing = () => {
  const [billingFrequency, setBillingFrequency] = useState("monthly")
  const [numberOfPeople, setNumberOfPeople] = useState(1)

  return (
    <Layout className="pricing">
      <Seo title="Pricing" description="Just $15 per user per month" />
      <section className="section--pricing">
        <div
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="1000"
          className="section--header"
        >
          <div className="section--header">
            <h1 className="main-title" style={{ marginTop: "0" }}>
              Pricing that scales with your business
            </h1>
            <h4
              style={{
                maxWidth: "400px",
                margin: "10px auto 20px auto",
                lineHeight: "25px",
              }}
            >
              Calculate your costs below and save money when paying yearly.
            </h4>
          </div>
        </div>
        <div className="container">
          <div
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="1000"
            className="calc-container grid grid-2"
          >
            <div className="col col-1">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  id="switch"
                  onChange={(e) =>
                    e.target.checked
                      ? setBillingFrequency("yearly")
                      : setBillingFrequency("monthly")
                  }
                />
                <div className="toggle-container">
                  <label htmlFor="switch">
                    <div className="toggle" name="switch"></div>
                    <div className="names">
                      <span className="on">Monthly</span>
                      <span className="off">Yearly</span>
                    </div>
                  </label>
                </div>
              </div>
              <div className="amount">
                <div className="amount--sm">
                  ${`${pricing[billingFrequency]}`}
                  <span className="amount--des">per user, per month </span>
                  <span className="freq-label">
                    {billingFrequency === "yearly" ? "billed yearly" : ""}
                  </span>
                </div>
              </div>
              <form>
                <label htmlFor="numberOfPeople">
                  How many people are on your team?
                </label>
                <input
                  min="1"
                  name="numberOfPeople"
                  onChange={(e) => setNumberOfPeople(e.target.value)}
                  type="number"
                  value={numberOfPeople}
                ></input>
              </form>
            </div>
            <div className="col col-2">
              <div className="amount amount--total amount--lg">
                <CountUp
                  duration={0.5}
                  end={calculate(numberOfPeople, pricing[billingFrequency])}
                  prefix="$"
                  preserveValue={true}
                  useEasing={false}
                />
                <span className="amount--des">total per month</span>
              </div>
              <div className="amount--breakdown">
                <span>
                  ${`${pricing[billingFrequency]}`} x{" "}
                  {`${parseInt(numberOfPeople) || 1}`}{" "}
                  {numberOfPeople === 1 ? "user" : "users"}{" "}
                  {billingFrequency === "yearly" && `x 12 months`}
                </span>
                <span>
                  $
                  {billingFrequency === "monthly"
                    ? `${calculate(numberOfPeople, pricing[billingFrequency])}`
                    : `${
                        calculate(numberOfPeople, pricing[billingFrequency]) *
                        12
                      }`}{" "}
                  / {billingFrequency.substring(0, billingFrequency.length - 2)}
                </span>
              </div>
              <motion.div
                whileHover={{ scale: 0.98 }}
                whileTap={{ scale: 1 }}
                className="mt-2 btn--container"
              >
                <a
                  className="btn btn--primary btn--green"
                  href="https://app.readyfive.io/signup"
                >
                  Start a 14 day free trial
                </a>
              </motion.div>
              <img
                src={blankStatePricing}
                alt="Blank State"
                style={{ display: "none" }}
              />{" "}
              {/* Image blank state is only visible prior to or during a calculation */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Pricing
